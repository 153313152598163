<template>
  <div class="page" style="max-width: 600px; margin: 0 auto">
    <div v-if="false && welcome_index == 0" style="height: 100%; background-color: #99d6ff; overflow: hidden">
      <img src="../../../public/img/jyj_wj_welcom.png" style="width: 100%; vertical-align: middle" />
      <div class="flexCenter" style="position: absolute; width: 100%; z-index: 100; bottom: 50px; left: 0">
        <div class="flexCenter btnjyj_welcome btnjyj_welcome_1" @click="welcome_index = 1">完 善 信 息</div>
      </div>
    </div>

    <div v-if="welcome_index == 0" class="bg_welcome bg_welcome1">
      <img src="../../../public/img/jyj_wj_welcom_1_1.png" style="width: 100%" />
      <div class="flexCloumn baseInfoBox">
        <div class="baseInfoForm">
          <div class="inputItem flexBetween">
            <van-field
              v-model="userMore.platform_name"
              is-link
              readonly
              label="毕业学校"
              placeholder="请选择毕业学校"
              @click="
                () => {
                  showSchool = true;
                }
              "
              input-align="right"
              required
            />
            <van-popup v-model="showSchool" round position="bottom">
              <van-cascader v-model="userMore.platform" title="请选择毕业学校" active-color="#1989fa" :options="schools" :field-names="fieldSchools" @close="showSchool = false" @finish="onCheckSchool" />
            </van-popup>
          </div>

          <div class="inputItem flexBetween mt10">
            <van-field
              v-model="userMore.class_name"
              is-link
              readonly
              label="班级"
              placeholder="请选择班级"
              @click="
                () => {
                  showClass = true;
                }
              "
              input-align="right"
              required
            />
            <van-popup v-model="showClass" round position="bottom">
              <van-cascader v-if="ClassList && ClassList.length > 0" v-model="userMore.class_id" title="请选择班级" active-color="#1989fa" :options="ClassList" :field-names="fieldClass" @close="showClass = false" @finish="onCheckClass" />
              <!-- 数据为空时显示的提示信息 -->
              <div v-if="!ClassList || ClassList.length == 0" class="flexCenter" style="height: 30vh">暂无班级数据</div>
            </van-popup>
          </div>
          <div class="inputItem flexBetween mt10">
            <van-cell-group style="width: 100%">
              <van-field v-model="userMore.stu_name" label="姓名" placeholder="请输入姓名" input-align="right" @blur="blurName" required style="border: none" />
            </van-cell-group>
          </div>
          <div class="inputItem mt10">
            <div class="flexBetween van-cell" style="width: 100%; padding: 10px 16px">
              <div class="van-field__label required">手机号</div>
              <div v-if="userMore.namedouble != 1">
                <input disabled class="van-field__control" v-model="userMore.dl_phone" type="tel" placeholder="根据班级姓名自动填充" style="border: 0; text-align: right" />
              </div>
              <div v-if="userMore.namedouble == 1">
                <van-field
                  v-model="userMore.dl_phone"
                  is-link
                  readonly
                  label=""
                  placeholder="请选择手机号"
                  @click="
                    () => {
                      showLxdh = true;
                    }
                  "
                  input-align="right"
                  style="padding-right: 0"
                />
                <van-popup v-model="showLxdh" round position="bottom">
                  <van-picker title="选择手机号" show-toolbar :columns="userMore.lxdhs" @confirm="onCheckLxdh" @cancel="showLxdh = false" />

                  <!-- 数据为空时显示的提示信息 -->
                  <div v-if="!userMore.lxdhs || userMore.lxdhs == 0" class="flexCenter" style="height: 30vh">暂无班级数据</div>
                </van-popup>
              </div>
            </div>
            <div v-if="false && userMore.dl_phone" style="padding: 0 16px; font-size: 14px; color: #0879ff">提示：若已更换手机号请删除当前手机号使用新手机号登录；请确认新手机号可接收验证码，一个手机号只能绑定一个学生，绑定后暂不支持解绑。</div>
          </div>

          <div class="inputItem flexBetween mt10">
            <div class="flexBetween van-cell required" style="width: 100%; padding: 10px 16px">
              <div>
                <input class="van-field__control" v-model="userMore.yzm" type="tel" placeholder="请输入验证码" style="border: 0; text-align: left" />
              </div>
              <div class="btnsms">
                <el-button style="background-color: #16b6ff" :disabled="leftSecond != 120" size="mini" type="primary" @click="sendSms_jyj">
                  {{ leftSecond == 120 ? "发送验证码" : leftSecond + "S后重发" }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="flexCenter btnjyj_welcome btnjyj_welcome_2 btnjyj_welcome_1" @click="loginjyj" style="font-weight: 700; font-size: 24px">登录</div>
      </div>
    </div>

    <div v-if="welcome_index == 1" class="bg_welcome bg_welcome2">
      <img src="../../../public/img/jyj_wj_welcome_2_1.png" style="width: 100%" />
      <div class="flexCloumn baseInfoBox">
        <div class="baseInfoForm">
          <div class="inputItem flexBetween">
            <van-field
              v-model="userMore.platform_name"
              is-link
              readonly
              label="毕业学校"
              placeholder="请选择毕业学校"
              @click="
                () => {
                  showSchool = true;
                }
              "
              input-align="right"
              required
              disabled
            />
            <van-popup v-model="showSchool" round position="bottom">
              <van-cascader v-model="userMore.platform" title="请选择毕业学校" active-color="#1989fa" :options="schools" :field-names="fieldSchools" @close="showSchool = false" @finish="onCheckSchool" />
            </van-popup>
          </div>

          <div class="inputItem flexBetween mt10">
            <van-field
              v-model="userMore.class_name"
              is-link
              readonly
              label="班级"
              placeholder="请选择班级"
              @click="
                () => {
                  showClass = true;
                }
              "
              input-align="right"
              required
              disabled
            />

            <van-popup v-model="showClass" round position="bottom">
              <van-cascader v-if="ClassList && ClassList.length > 0" v-model="userMore.class_id" title="请选择班级" active-color="#1989fa" :options="ClassList" :field-names="fieldClass" @close="showClass = false" @finish="onCheckClass" />
              <!-- 数据为空时显示的提示信息 -->
              <div v-if="!ClassList || ClassList.length == 0" class="flexCenter" style="height: 30vh">暂无班级数据</div>
            </van-popup>
          </div>
          <div class="inputItem flexBetween mt10">
            <van-cell-group style="width: 100%">
              <van-field v-model="userMore.stu_name" label="姓名" placeholder="请输入姓名" input-align="right" @blur="blurName" required disabled />
            </van-cell-group>
          </div>

          <div class="inputItem flexBetween mt10">
            <van-cell-group style="width: 100%">
              <van-field v-model="userMore.lxdh" label="手机号" placeholder="请输入手机号" input-align="right" @blur="blurName" required disabled />
            </van-cell-group>
          </div>

          <div class="inputItem flexBetween mt10">
            <van-cell-group style="width: 100%">
              <van-field v-model="userMore.dl_phone" label="已绑定手机号" placeholder="请输入手机号" input-align="right" @blur="blurName" required disabled />
            </van-cell-group>
          </div>

          <div class="inputItem flexBetween mt10">
            <van-cell title="性别" required>
              <template #right-icon>
                <van-radio-group v-model="userMore.gender" direction="horizontal">
                  <van-radio disabled name="男" checked-color="#5BC4FF">男</van-radio>
                  <van-radio disabled name="女" checked-color="#5BC4FF">女</van-radio>
                </van-radio-group>
              </template>
            </van-cell>
          </div>
          <div class="inputItem flexBetween mt10">
            <van-field v-model="userMore.edu" is-link readonly label="学历" placeholder="请选择学历" @click="showXueli = true" input-align="right" required />
            <van-popup v-model="showXueli" position="bottom">
              <van-picker show-toolbar :columns="XueliList" @confirm="onConfirmXueli" @cancel="showXueli = false" />
            </van-popup>
          </div>
          <div class="inputItem flexBetween mt10">
            <van-field v-model="userMore.zzmm" is-link readonly label="政治面貌" placeholder="请选择政治面貌" @click="showPolitical = true" input-align="right" required />
            <van-popup v-model="showPolitical" position="bottom">
              <van-picker show-toolbar :columns="politicalList" @confirm="onConfirmPolitical" @cancel="showPolitical = false" />
            </van-popup>
          </div>
          <div class="inputItem flexBetween mt10">
            <van-field v-model="userMore.position" is-link readonly label="在校担任职务" placeholder="请选择在校担任职务" @click="showDuty = true" input-align="right" required />
            <van-popup v-model="showDuty" position="bottom">
              <van-picker show-toolbar :columns="duties" @confirm="onConfirmDuty" @cancel="showDuty = false" />
            </van-popup>
          </div>
          <div class="inputItem flexBetween mt10">
            <van-field v-model="userMore.czdz" is-link readonly label="常住地址" placeholder="请选择常住地址" @click="showAddress = true" input-align="right" required />
            <van-popup v-model="showAddress" position="bottom">
              <van-picker show-toolbar :columns="addresses" @confirm="onConfirmAddress" @cancel="showAddress = false" />
            </van-popup>
          </div>
        </div>
        <div class="flexCenter btnjyj_welcome btnjyj_welcome_2" @click="goQuxiang">您现在的状态是？</div>
      </div>
    </div>

    <!--  v-if="false"👇 -->

    <div v-if="false" style="height: 100%; background-color: #99d6ff">
      <div style="position: relative">
        <img :src="'/img/wjbghd1.jpg'" style="width: 100%; vertical-align: top" />
        <div style="position: absolute; top: 10vw">
          <div style="width: 70%; margin: 0 auto">
            <img v-if="planInfo" :src="planInfo.config.kvalue" style="width: 100%" />
          </div>
        </div>
      </div>

      <div class="boxbg" style="min-height: 60vh" :style="{ backgroundImage: 'url(/img/wjbghd2.jpg)' }">
        <div style="width: 80%; margin: 0 auto" v-if="planInfo">
          <img :src="'/img/wjsy_01.png'" style="width: 100%; vertical-align: bottom" />
          <div class="boxbg" :style="{ backgroundImage: 'url(/img/wjsy_03.png)' }">
            <div style="padding: 0 20px; word-break: break-all; font-size: 20px; color: dodgerblue">
              {{ planInfo.plan_name }}
            </div>
          </div>
          <img :src="'/img/wjsy_05.png'" style="width: 100%; vertical-align: top" />
          <div class="boxbg" :style="{ backgroundImage: 'url(/img/wjsy_07.png)' }">
            <div class="ivst_desc" style="padding: 0 20px; word-break: break-all" v-html="planInfo.ivst_desc"></div>
          </div>
          <img :src="'/img/wjsy_09.jpg'" style="width: 100%; vertical-align: top" />
        </div>

        <div style="position: relative; width: 50%; vertical-align: top; margin: 0 auto; padding-top: 30px; box-sizing: content-box">
          <img :src="'/img/btnsave.png'" style="width: 100%" />
          <div style="position: absolute; width: 100%; height: 100%; z-index: 1; top: 0">
            <!-- <a :href="urlScheme" class="btna"></a> -->
            <a @click="openForm" class="btna"></a>
          </div>
        </div>
      </div>

      <div style="position: relative">
        <img :src="'/img/wjbghd3.jpg'" style="width: 100%; vertical-align: top" />
        <div style="position: absolute; top: 0"></div>
      </div>
    </div>

    <div v-if="showUser" style="position: absolute; width: 100%; height: 100%; top: 0; background: rgba(0, 0, 0, 0.5)">
      <div style="height: 30vw"></div>
      <div style="width: 70%; margin: 0 auto">
        <img src="../../../public/img/wj_wsxx_copy.png" style="width: 100%; vertical-align: middle" />
        <div class="flexCenter" style="background-color: #fff; padding: 10px 0">
          <span style="width: 80%; margin: 0 auto; color: #33a9ff">请选择学校、班级并填写姓名</span>
        </div>
      </div>
      <div style="width: 70%; margin: 0 auto; background-color: #fff; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px">
        <div v-if="schools.length" style="border-bottom: 1px solid #f6f6f6; width: 80%; margin: 0 auto; padding: 10px 0">
          <span class="required" style="font-size: 14px">学校：</span>
          <div style="float: right; position: relative; width: 70%; text-align: right">
            <div>
              <input type="text" :value="userMore.platform" style="width: 100%; outline: none; border: 0; text-align: right; float: right" placeholder="请选择学校" />
            </div>
            <div style="position: absolute; top: 0; width: 100%; height: 100%">
              <select v-model="userMore.tpmid" style="opacity: 0; width: 100%" @change="changeSchool">
                <option v-for="(y, i) in schools" :key="i" :value="y.id">{{ y.platform }}</option>
              </select>
            </div>
          </div>
        </div>

        <div style="border-bottom: 1px solid #f6f6f6; width: 80%; margin: 0 auto; padding: 10px 0">
          <span class="required" style="font-size: 14px">班级：</span>
          <div style="float: right; position: relative; width: 70%; text-align: right">
            <div>
              <input type="text" :value="userMore.class_name" style="width: 100%; outline: none; border: 0; text-align: right; float: right" placeholder="请选择班级" />
            </div>
            <div style="position: absolute; top: 0; width: 100%; height: 100%">
              <select v-model="userMore.class_id" style="opacity: 0; width: 100%" @change="changeSelect">
                <option v-for="(y, i) in ClassList" :key="i" :value="y.id">{{ y.class_name }}</option>
              </select>
            </div>
          </div>
        </div>

        <div style="border-bottom: 1px solid #f6f6f6; width: 80%; margin: 0 auto; padding: 16px 0">
          <span class="required" style="font-size: 14px">姓名：</span>
          <input type="text" v-model="userMore.stu_name" style="width: 50%; outline: none; border: 0; text-align: right; float: right" placeholder="请输入姓名" />
        </div>

        <div style="margin: 30px auto; width: 60%; padding-bottom: 30px" @click="confirmChoujiang">
          <img src="../../../public/img/wj02_btn.png" style="width: 100%" />
        </div>
      </div>
    </div>

    <div id="toast" class="toast">请选择完整</div>

    <div id="alert" class="alert flexCenter">
      <div class="alertbox flexCloumn">
        <div class="flexCenter" style="width: 100%">
          <img src="../../../public/img/jyj_wj_alert_1.png" style="width: 67px; height: 67px; vertical-align: middle; margin-top: -43px" />
        </div>
        <!-- <div class="alerttitle">提示</div> -->
        <div id="alertcontent" class="alertcontent" style="width: 100%"></div>
        <div class="flexCenter" style="width: 100%">
          <div class="flexCenter btnjyj_welcome btnjyj_welcome_3" @click="hideAlert">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import jiami from "../../utils/aes.js";

export default {
  data() {
    return {
      urlScheme: "",
      planInfo: null,
      showUser: false,
      userMore: {},
      ClassList: [],
      schools: [],
      stu_id: "",
      target_classes: "",
      choosedClasses: 0,
      routerParams: {},
      welcome_index: 0,
      showSchool: false,
      fieldSchools: {
        text: "platform",
        value: "id",
        children: "children",
      },
      showClass: false,
      showLxdh: false,
      fieldClass: {
        text: "class_name",
        value: "id",
        children: "children",
      },
      showXueli: false,
      XueliList: ["三年制中职", "五年制高职"],
      showPolitical: false,
      politicalList: ["群众", "团员", "入党积极分子"],
      showDuty: false,
      duties: ["班级干部", "系部学生干部", "校团委委员", "校学生会干部", "未担任职务"],
      showAddress: false,
      addresses: ["南京", "非南京"],
      templateId: null,
      jioned: false,
      leftSecond: 120,
      loading: false,
      templateJyqxs: "",
      stime: '',
      etime: '',
      ntime: '',
    };
  },

  mounted() {
    console.log(this.$route.query);
    let _this = this;
    if (this.$route.query.p) {
      let ids = this.$route.query.p.split("a");
      let routerParams = {
        pmid: ids[0],
        plan_id: ids[1],
      };
      // console.log(this.$route.query.uid)
      this.routerParams = routerParams;
      this.getPlanInfo();
      this.getSchools();
      setTimeout(() => {
        if (_this.routerParams.pmid && (!_this.ClassList || _this.ClassList.length == 0)) {
          _this.getClasses();
        }
      }, 1000);
    }
  },
  methods: {
    getPlanInfo() {
      this.$http.post("/api/ivst_welcome", { plan_id: this.routerParams.plan_id }).then((res) => {
        this.planInfo = res.data;
        window.document.title = res.data.ivst_title;
        console.log(404, res.data.ntime, res.data.stime, res.data.ntime , res.data.etime)
        console.log(405, res.data.ntime, res.data.etime, res.data.ntime > res.data.etime)
        let date0 = this.$moment( res.data.ntime);
        let date1 = this.$moment( res.data.stime);
        let date2 = this.$moment(res.data.etime);
        this.stime = date1
        this.etime = date2
        this.ntime = date0
        console.log(406, date0.isBefore(date1))
        console.log(407, date0.isAfter(date2))

        if (date0.isBefore(date1)) {
          this.showAlert("问卷未开始~")
        } else if (date0.isAfter(date2)) {
          console.log(410)
          this.showAlert("问卷已结束~")
        }

        //生成urlScheme
        // if (this.planInfo.count == 1) {
        //   this.$http.post("/api/ivst_generatescheme", { pmid: this.planInfo.pmid, plan_id: this.$route.params.id }).then(ok => {
        //     if (ok.data.openlink) {
        //       this.urlScheme = ok.data.openlink
        //     }
        //   })
        // } else {

        // }
      });
    },
    getSchools() {
      let _this = this;
      this.$http
        .post("/api/sys_platform_list_h5public", {
          page: 1,
          pagesize: 100,
        })
        .then((res) => {
          res.data.data.shift();
          this.schools = res.data.data;
          if (this.routerParams.pmid && this.schools && this.schools.length > 0) {
            this.schools.map((a) => {
              if (this.routerParams.pmid == a.id) {
                this.userMore.platform_name = a.platform;
                this.userMore.platform = a.id;
                // this.ClassList = a.children
                _this.getClasses();
              }
            });
          }
        });
    },
   
    getClasses() {
      this.$http.post("/api/ivst_plan", { plan_id: this.routerParams.plan_id, edit: 1 }).then((res) => {
        let e = res.data;
        e.moban_arr = e.temp;
        let info = JSON.parse(JSON.stringify(e));
        let sta_end_time = [];
        sta_end_time.push(new Date(e.sta_time));
        sta_end_time.push(new Date(e.end_time));
        info.sta_end_time = sta_end_time;
        if (info.moban_arr && info.moban_arr.length > 0) {
          info.moban_arr.map((a, index) => {
            this.target_classes = a.target_classes ? a.target_classes : "";
            this.choosedClasses = 0;

            if (this.target_classes.indexOf(",") > 0) {
              this.choosedClasses = this.target_classes.split(",").length - 2;
            } else if (this.target_classes > 0) {
              this.choosedClasses = 1;
            }
            if (this.choosedClasses > 0) {
              this.$http
                .post("/api/h5_ivst_checked_class", {
                  ids: this.target_classes,
                  jyj_pmid: this.userMore.platform || this.routerParams.pmid,
                })
                .then((res) => {
                  res.data.map((a, ia) => {
                    a.class_name = a.zy_xuezhi + " - " + a.class_name;
                  });
                  this.ClassList = res.data;
                });
            }
          });
        }
        if (res.data.temp && res.data.temp.length > 0) {
          this.templateId = res.data.temp[0].id;
          this.templateJyqxs = res.data.temp[0].jyqx;
        }
      });
    },

    goNext() {},

    sendSms_jyj() {
      if (this.leftSecond == 120) {
        if (!this.userMore.dl_phone) {
          Toast("请输入手机号码！");
          return;
        }
        const reg = /^1\d{10}$/;
        if (!reg.test(this.userMore.dl_phone)) {
          Toast("手机号码格式有误！");
          return;
        } else {
          if (this.loading) {
            return;
          } else {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
            }, 3000);
            this.$http
              .post("/api/qsx_smsyzm_jyj", {
                jyj_pmid: this.userMore.pmid || 211,
                phone: this.userMore.dl_phone,
                session_id: `qsxjyj_${this.userMore.dl_phone}`,
              })
              .then((ok) => {
                Toast("短信验证码发送成功！");
                this.leftSecond = 119;
                const timer = setInterval(() => {
                  this.leftSecond--;
                  if (this.leftSecond < 0) {
                    this.leftSecond = 120;
                    clearInterval(timer);
                  }
                }, 1000);
              });
          }
        }
      }
    },
    async loginjyj() {
      if (this.ntime.isBefore(this.stime)) {
        this.showAlert("问卷未开始~")
        return

      } else if (this.ntime.isAfter(this.etime)) {
        this.showAlert("问卷已结束~")
        return
      }
      this.checkUser().then(async (_) => {
        console.log(this.templateJyqxs, this.userMore.jyqx);
        // if (!this.userMore.jyqx || (this.userMore.jyqx && this.templateJyqxs.indexOf(this.userMore.jyqx) < 0)) {
        //   this.showAlert("您无需填写问卷");
        //   return;
        // }

        if (this.userMore.dl_phone && this.userMore.yzm) {
          let formdata = {
            stu_id: this.stu_id,
            phone: this.userMore.dl_phone,
            yzm: this.userMore.yzm.toLowerCase(),
            session_id: `qsxjyj_${this.userMore.dl_phone}`,
            jyj_pmid: this.userMore.pmid,
            day: 7, //7天后过期
          };

          formdata = await jiami.encrypt(JSON.stringify(formdata));
          this.$http.post("/api/stu_yzm_login", { formdata: formdata }).then(
            (res) => {
              if (res.data && res.data.stu_id) {
                Toast("登录成功！");
                this.welcome_index = 1;
                this.userMore.dl_phone = res.data.dl_phone;
              } else {
                if (res.data.msg.indexOf("您已绑定手机号") > -1 || res.data.msg.indexOf("手机号已被绑定") > -1) {
                  this.showAlert(res.data.msg);
                } else {
                  Toast(res.data.msg);
                }
              }
            },
            (err) => {}
          );
        } else {
          Toast("请填写手机号和验证码~");
        }
      });
    },
    openForm() {
      this.showUser = true;
    },
    changeSelect(e) {
      this.ClassList.map((a) => {
        if (a.id == this.userMore.class_id) {
          this.userMore.class_name = a.class_name;
        }
      });
      this.showClass = false;
    },
    changeSchool() {
      this.ClassList = [];
      this.userMore.class_name = "";
      this.schools.map((a) => {
        if (a.id == this.userMore.platform) {
          this.userMore.platform_name = a.platform;
          this.userMore.platform = a.id;
          // this.ClassList = a.children
          this.getClasses();
        }
      });
    },
    onCheckSchool(e) {
      console.log(e, e.selectedOptions[0].platform);
      this.ClassList = [];
      this.userMore.class_name = "";
      this.userMore.platform_name = e.selectedOptions[0].platform;
      this.userMore.platform = e.selectedOptions[0].id;
      this.getClasses();
      this.showSchool = false;
    },
    onCheckClass(e) {
      this.ClassList.map((a) => {
        if (a.id == this.userMore.class_id) {
          this.userMore.class_name = a.class_name;
        }
      });
      this.showClass = false;
      if (this.userMore.class_id && this.userMore.stu_name) {
        this.checkUser();
      }
    },
    onCheckLxdh(value) {
      console.log(634, value);
      this.userMore.dl_phone = value;
      this.showLxdh = false;
       if (this.userMore.class_id && this.userMore.stu_name) {
        this.checkUser();
      }
    },
    onConfirmXueli(e) {
      this.userMore.edu = e;
      this.showXueli = false;
    },

    onConfirmPolitical(e) {
      this.userMore.zzmm = e;
      this.showPolitical = false;
    },

    onConfirmDuty(e) {
      this.userMore.position = e;
      this.showDuty = false;
    },

    onConfirmAddress(e) {
      this.userMore.czdz = e;
      this.showAddress = false;
    },
    blurName() {
      if (this.userMore.class_id && this.userMore.stu_name) {
        this.checkUser();
      }
    },
    checkUser() {
      return new Promise((resolve, reject) => {
        if (!this.userMore.platform) {
          Toast("请选择您的毕业学校");
          return;
        }
        if (!this.userMore.class_id) {
          Toast("请选择您的班级");
          return;
        }
        if (!this.userMore.stu_name) {
          Toast("请输入您的姓名");
          return;
        }
        // const reg = /^1\d{10}$/;
        // if (this.userMore.lxdh && !reg.test(this.userMore.lxdh)) {
        //   Toast("手机号码格式有误，请检查")
        //   return
        // }
        this.userMore.pmid = this.userMore.platform ?? this.routerParams.pmid;
        this.userMore.jyj_pmid = this.userMore.platform ?? this.routerParams.pmid;
        this.userMore.stu_name = this.userMore.stu_name ? this.userMore.stu_name.replace(/\s+/g, "") : "";
        let targetClasses = this.planInfo.target_classes.split(",");
        if (targetClasses.indexOf(this.userMore.class_id.toString()) < 0) {
          Toast("您无需填写问卷");
          return;
        }
        let params = {
          jyj_pmid: this.userMore.jyj_pmid,
          class_id: this.userMore.class_id,
          stu_name: this.userMore.stu_name,
        };
        if(this.userMore.dl_phone){
          params.lxdh = this.userMore.dl_phone
        }
        this.$http
          .post("/api/h5_ivst_check_user", params)
          .then((res) => {
            if (res.data.id) {
              this.stu_id = res.data.id;
              this.userMore.edu = this.userMore.edu || res.data.edu;
              this.userMore.position = this.userMore.position || res.data.position;
              this.userMore.zzmm = this.userMore.zzmm || res.data.zzmm;
              this.userMore.czdz = this.userMore.czdz || res.data.czdz;
              this.userMore.gender = this.userMore.gender || res.data.gender;
              this.userMore.lxdh = this.userMore.lxdh || res.data.lxdh;
              this.userMore.dl_phone = this.userMore.dl_phone || res.data.dl_phone || res.data.lxdh;
              console.log(709, res.data);
              this.userMore.namedouble = res.data.namedouble || 0;
              this.userMore.lxdhs = res.data.lxdhs || [];

              if (res.data.xuezhi) {
                this.XueliList.map((a) => {
                  if (a.indexOf(res.data.xuezhi) > -1) {
                    this.userMore.edu = a;
                  }
                });
              }
              localStorage.setItem("ivst_stu_id", this.stu_id);
              localStorage.setItem("ivst_pmid", this.userMore.platform);
              let _this = this;
              setTimeout(() => {
                // console.log(505,_this.userMore)
                _this.userMore = { ..._this.userMore };
                // _this.$set(_this, 'userMore', _this.userMore)
              }, 80);
              resolve();
            } else {
              if (this.userMore.lxdh) {
                Toast("班级姓名手机号未匹配，请仔细核对~");
              } else {
                Toast("班级姓名未匹配，请仔细核对~");
              }
              reject();
            }
          })
          .catch((err) => {
            reject();
          });
      });
    },
    goQuxiang() {
      // if (!this.userMore.class_id) {
      //   Toast("请选择您的班级")
      //   return
      // }
      // if (!this.userMore.stu_name) {
      //   Toast("请输入您的姓名")
      //   return
      // }
      // const reg = /^1\d{10}$/;
      // if (this.userMore.lxdh && !reg.test(this.userMore.lxdh)) {
      //   Toast("手机号码格式有误，请检查")
      //   return
      // }

      if (!this.userMore.edu) {
        Toast("请选择您的学历");
        return;
      }
      if (!this.userMore.zzmm) {
        Toast("请选择您的政治面貌");
        return;
      }
      if (!this.userMore.position) {
        Toast("请选择您的在校担任职务");
        return;
      }
      if (!this.userMore.czdz) {
        Toast("请选择您的常住地址");
        return;
      }
      this.checkUser().then((_) => {
        // localStorage.setItem("ivst_stu_id", this.stu_id)
        // localStorage.setItem("ivst_pmid", this.userMore.platform)
        // this.$router.push(`/h5_ivst_plan?stuid=${this.stu_id}&pid=${this.routerParams.plan_id}&pmid=${this.userMore.platform}`)
        // http://localhost:9020/#/h5_ivst_plan?stuid=17721&pid=53&pmid=211

        //page：  h5_ivst_plan:  /h5/ivstplan.vue
        // this.saveUserExtraInfo()
        // return
        this.checkAnswerIvst().then((joined) => {
          if (joined == 1) {
            this.showAlert("您已参与过，请勿重复参与！");
          } else {
            this.saveUserExtraInfo();
            this.$router.push({
              path: "h5_ivst_plan",
              query: {
                stuid: this.stu_id,
                pid: this.routerParams.plan_id,
                pmid: this.userMore.pmid,
              },
            });
          }
        });

        // this.showUser = false
      });
    },
    saveUserExtraInfo() {
      let _this = this;
      let params = { ..._this.userMore };
      params.id = this.stu_id;
      params.jyj_pmid = params.pmid;
      this.$http.post("/api/h5_ivst_save_user", params).then((res) => {});
    },

    checkAnswerIvst() {
      return new Promise((resolve) => {
        this.$http
          .post("/api/ivst_temp_one", {
            plan_id: this.routerParams.plan_id,
            id: this.templateId,
            jyj_uid: this.stu_id,
            jyj_pmid: this.userMore.jyj_pmid,
            jyj_utype: 0,
          })
          .then((res) => {
            document.title = res.data.ivst_title;
            if (res.data.code == 400) {
              resolve(0);
              Toast("问卷已经更新，请重新点击链接进入。");
              this.getClasses();
              return;
            } else if (res.data.answers && res.data.answers.length > 0) {
              this.jioned = true;
              resolve(1);
            } else {
              resolve(0);
              if (res.data.hasAuth || res.data.open) {
              } else {
                this.showAlert("您不在此次问卷调查范围内！无需参与！");
              }
            }
          });
      });
    },

    showAlert(msg) {
      $("#alertcontent").html(msg);
      $("#alert")
        .css("display", "flex")
        .animate(
          {
            opacity: 1,
          },
          200,
          "linear",
          function () {}
        );
    },
    hideAlert() {
      $("#alert").animate(
        {
          opacity: 0,
        },
        "linear",
        3000,
        function () {
          $("#alert").hide();
        }
      );
      if (this.viewResult) {
        //this.$router.push("/h5result/"+this.$route.params.id)
      }
      if (this.jioned) {
        this.$router.push({
          path: "h5_ivst",
          query: {
            pid: this.routerParams.plan_id,
            id: this.templateId,
            pmid: this.userMore.jyj_pmid,
          },
        });
      }
    },

    confirmChoujiang() {
      if (!this.userMore.class_id) {
        this.showToast("请选择您的班级");
        return;
      }
      if (!this.userMore.stu_name) {
        this.showToast("请输入您的姓名");
        return;
      }
      this.userMore.pmid = this.userMore.tpmid ?? this.routerParams.pmid;
      this.userMore.jyj_pmid = this.userMore.tpmid ?? this.routerParams.pmid;
      this.userMore.stu_name = this.userMore.stu_name ? this.userMore.stu_name.replace(/\s+/g, "") : "";
      let targetClasses = this.planInfo.target_classes.split(",");
      if (targetClasses.indexOf(this.userMore.class_id.toString()) < 0) {
        return this.showToast("您无需填写问卷");
      }
      this.$http.post("/api/h5_ivst_check_user", this.userMore).then((res) => {
        if (res.data.id) {
          this.stu_id = res.data.id;
          localStorage.setItem("ivst_stu_id", res.data.id);
          localStorage.setItem("ivst_pmid", this.userMore.pmid);
          // this.$router.push(`/h5_ivst_plan?stuid=${res.data.id}&pid=${this.routerParams.plan_id}&pmid=${this.userMore.pmid}`)
          // http://localhost:9020/#/h5_ivst_plan?stuid=17721&pid=53&pmid=211
          console.log(261261, window.location.href);
          console.log(`/h5_ivst_plan?stuid=${res.data.id}&pid=${this.routerParams.plan_id}&pmid=${this.userMore.pmid}`);
          this.$router.push({
            path: "h5_ivst_plan",
            query: {
              stuid: res.data.id,
              pid: this.routerParams.plan_id,
              pmid: this.userMore.pmid,
            },
          });
          this.showUser = false;
        } else {
          this.showToast("班级姓名未匹配，请检查~");
        }
      });
    },
    showToast(msg) {
      $("#toast")
        .text(msg)
        .show()
        .animate(
          {
            opacity: 1,
          },
          200,
          "linear",
          function () {
            setTimeout(() => {
              $("#toast").animate(
                {
                  opacity: 0,
                },
                "linear",
                3000,
                function () {
                  $("#toast").hide();
                }
              );
            }, 2000);
          }
        );
    },
  },
};
</script>

<style scoped lang="less" type="text/less">
.btnjyj_welcome111::before {
  content: attr(data-title);
  position: absolute;
  background-image: linear-gradient(#006d9f, #006d9f);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0;
}

.btnjyj_welcome {
  width: 200px;
  height: 50px;
  background: linear-gradient(to bottom, #9fe1ff, #00aeff);
  border-radius: 25px;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
}

.btnjyj_welcome_1 {
  /*text-shadow:
      -1px -1px 0 #006D9F,
        1px -1px 0 #006D9F,
        -1px 1px 0 #006D9F,
        1px 1px 0 #006D9F;*/
  -webkit-text-stroke: 1px #006d9f;
}

.bg_welcome {
  width: 100vw;
  overflow-x: hidden;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .baseInfoBox {
    width: 100%;
    align-items: center;

    .baseInfoForm {
      width: calc(100% - 40px);
      margin: 0 auto;
      border: 2px solid #fff;
      border-radius: 10px;
      padding: 16px 12px;
      background-color: #ffffffef;

      .inputItem {
        width: 100%;
        border-radius: 10px;
        overflow: hidden;

        .van-cell {
          font-size: 16px;

          .van-field__label {
            color: #3d3d3d;
          }

          /* 修改 van-field 的输入框颜色 */
          /deep/ .van-field__control {
            color: #8394b2;
            font-weight: 600;
          }
        }

        input:disabled {
          color: #8394b2 !important;
          /*-webkit-text-fill-color: unset !important;*/
        }
      }
    }
  }

  .btnjyj_welcome_2 {
    margin-top: 40px;
    margin-bottom: 40px;
    width: calc(100% - 40px);
    height: 54px;
    background: linear-gradient(to bottom, #71d7ff, #0073ff);
    border-radius: 25px;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
  }
}

.bg_welcome1 {
  min-height: 100%;
  background-image: url("../../../public/img/jyj_wj_welcom_1.png");
}

.bg_welcome2 {
  background-image: url("../../../public/img/jyj_wj_welcome_3.png");
}

.boxbg {
  background-size: 100% auto;
  background-repeat: repeat;
}

.btna {
  width: 100%;
  height: 100%;
  display: block;
}

.toast {
  position: fixed;
  width: 70%;
  left: 15%;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  bottom: 50%;
  background: orangered;
  color: #ffffff;
  font-size: 14px;
  opacity: 0;
  display: none;
}

.alert {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
}

.alertbox {
  position: relative;
  width: 75%;
  background: #ffffff;
  background: linear-gradient(to bottom, #e5eafc, #ffffff);
  border-radius: 10px;
  padding: 10px;
  margin: 0 auto;
}

.alerttitle {
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid #f6f6f6;
  padding-bottom: 10px;
}

.alertcontent {
  padding: 20px 0;
  word-break: break-all;
  text-align: center;
}

.alertbutton {
  border-top: 1px solid #f6f6f6;
  padding: 10px;
  text-align: center;
  background-color: #007aff;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
}

.btnjyj_welcome_3 {
  width: calc(100% - 40px);
  height: 40px;
  border-radius: 20px;
  margin-bottom: 15px;
}

.required {
  position: relative;
}

.required:before {
  position: absolute;
  content: "*";
  color: #f56c6c;
  margin-left: -8px;
}
</style>

<style>
.ivst_desc p:nth-child(1) {
  margin-top: 0;
}

.ivst_desc p:last-child {
  margin-bottom: 0;
}
</style>
